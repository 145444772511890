<template>
  <div class="page">
    <DaoRule />
    <div class="info-box">
      <!-- logo -->
      <div class="key-value">
        <div class="key">
          {{ createDao.logo }} <span class="fu-title important">(100*100)</span>
        </div>
        <VanUploader
          translate="no"
          class="logo-box"
          v-model="imageList"
          multiple
          accept="image/jpg, image/jpeg, image/png"
          :max-count="1"
          :after-read="handleUpLogo"
        >
          <div class="upload-icon">+</div>
        </VanUploader>
        <!-- <VanField
          class="input-box"
          input-align="center"
          v-model="params.logo"
          :placeholder="createDao.pleaseInput"
          maxlength="200"
        ></VanField> -->
      </div>
      <!-- 名字 中-->
      <div class="key-value">
        <div class="key">
          {{ createDao.daoName }}
          <span class="fu-title"> ({{ createDao.cn }})</span>
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params.nameCn"
          :placeholder="createDao.pleaseInput"
          maxlength="40"
        ></VanField>
      </div>
      <!-- 名字 英-->
      <div class="key-value">
        <div class="key">
          {{ createDao.daoName }}
          <span class="fu-title"> ({{ createDao.en }})</span>
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params.nameEn"
          :placeholder="createDao.pleaseInput"
          maxlength="40"
        ></VanField>
      </div>
      <!-- 简介 中-->
      <div class="key-value">
        <div class="key">
          {{ createDao.desc }}
          <span class="fu-title"> ({{ createDao.cn }})</span>
        </div>
        <VanField
          translate="no"
          type="textarea"
          cols="10"
          rows="5"
          maxlength="100"
          class="textarea-box"
          v-model="params.descCn"
          show-word-limit
          :placeholder="createDao.pleaseInput"
        ></VanField>
      </div>
      <!-- 简介 英-->
      <div class="key-value">
        <div class="key">
          {{ createDao.desc }}
          <span class="fu-title"> ({{ createDao.en }})</span>
        </div>
        <VanField
          translate="no"
          type="textarea"
          cols="10"
          rows="5"
          maxlength="200"
          class="textarea-box"
          v-model="params.descEn"
          show-word-limit
          :placeholder="createDao.pleaseInput"
        ></VanField>
      </div>
      <!-- 网站（选填）-->
      <div class="key-value">
        <div class="key">
          {{ createDao.webSite }}
          <span class="fu-title"> ({{ common.optional }})</span>
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params.webSite"
          :placeholder="createDao.pleaseInput"
          maxlength="200"
        ></VanField>
      </div>
      <!-- Twitter（选填）-->
      <div class="key-value">
        <div class="key">
          {{ createDao.Twitter }}
          <span class="fu-title"> ({{ common.optional }})</span>
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params.Twitter"
          :placeholder="createDao.pleaseInput"
          maxlength="200"
        ></VanField>
      </div>
      <!-- Telegram（选填）-->
      <div class="key-value">
        <div class="key">
          {{ createDao.Telegram }}
          <span class="fu-title"> ({{ common.optional }})</span>
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params.Telegram"
          :placeholder="createDao.pleaseInput"
          maxlength="200"
        ></VanField>
      </div>
      <!--Discord（选填）-->
      <div class="key-value">
        <div class="key">
          {{ createDao.Discord }}
          <span class="fu-title"> ({{ common.optional }})</span>
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params.Discord"
          :placeholder="createDao.pleaseInput"
          maxlength="200"
        ></VanField>
      </div>
      <!-- 代币合约地址-->
      <div class="key-value">
        <div class="key">
          {{ createDao.tokenAddress }}
          <span class="fu-title">(BEP20)</span>
        </div>
        <VanField
          translate="no"
          class="input-box input-address"
          input-align="center"
          v-model="tokenAddress"
          :placeholder="createDao.pleaseInput"
          disabled
        ></VanField>
      </div>
    </div>
    <div class="token-info">
      <div>
        {{ createDao.tokenName }}:
        <VanLoading size="14" color="#105CFB" v-if="loadingTokenName" />
        <span class="token-green" v-else>{{ tokenName }}</span>
      </div>
      <div>
        {{ createDao.totalSupply }}:
        <VanLoading size="14" color="#105CFB" v-if="loadingSupply" />
        <span class="token-green" v-else>{{
          $gbUtils.formatNumber(totalSupply)
        }}</span>
      </div>
    </div>
    <div class="lp-info">
      <div class="key">{{ createDao.isSupportedLp }}</div>
      <div class="btn-groups">
        <VanButton
          class="btn"
          :class="{ active: isSupportLp }"
          @click="handleChange(true)"
          disabled
        >
          {{ createDao.supported }}
        </VanButton>
        <VanButton
          class="btn"
          :class="{ active: !isSupportLp }"
          @click="handleChange(false)"
          disabled
        >
          {{ createDao.notSupported }}
        </VanButton>
      </div>
      <!-- 请输入lp合约地址 -->
      <div class="key-valye" v-if="isSupportLp">
        <div class="key">
          {{ createDao.pleaseInput + createDao.lpAddress }}
          <span class="fu-title">(BEP20)</span>
        </div>
        <VanField
          translate="no"
          class="input-box lp-address"
          input-align="center"
          v-model="lpAddress"
          :placeholder="createDao.pleaseInput"
          maxlength="200"
          disabled
        ></VanField>
      </div>
    </div>
    <!-- 修改 -->
    <VanButton
      class="create-btn"
      :loading="loadingAdd"
      :disabled="
        !params.logo ||
        !params.nameCn ||
        !params.nameEn ||
        !params.descCn ||
        !params.descEn
      "
      @click="handleAdd"
    >
      {{ common.confirm }}
    </VanButton>
  </div>
</template>

<script>
import daoObj from '../../contracts/contractDao'
import tokenObj from '../../contracts/contractToken'
import DaoRule from '../../components/DaoRule.vue'
import { mapState } from 'vuex'
import { uploadFile } from '../../utils/upload'
export default {
  name: '',
  components: { DaoRule },
  data () {
    return {
      daoDetail: {},
      daoAt: '',
      point: 0.000001,
      imageList: [],
      params: {
        logo: '',
        nameCn: '',
        nameEn: '',
        descCn: '',
        descEn: '',
        Twitter: '',
        Telegram: '',
        Discord: '',
        webSite: ''
      },
      tokenAddress: '',
      isSupportLp: true,
      lpAddress: '',
      tokenName: '-',
      loadingTokenName: false,
      totalSupply: 0,
      loadingSupply: false,
      loadingAdd: false,
      hasSupport: true // 已经支持lp
    }
  },
  computed: {
    ...mapState(['myAccount']),
    rule () {
      return this.$t('createDao.rule', { point: this.$gbUtils.accMul(this.point, 100) })
    },
    createDao () {
      return this.$t('createDao')
    },
    common () {
      return this.$t('common')
    }
  },
  watch: {
    tokenAddress (val) {
      this.getTokenInfo(val)
    }
  },
  methods: {
    init () {
      const daoDetail = localStorage.getItem('daoDetail')
      if (!daoDetail) return this.$router.replace('/my')
      this.daoDetail = JSON.parse(daoDetail)
      this.formatData()
    },
    formatData () {
      this.daoAt = this.daoDetail.daoAt
      this.tokenAddress = this.daoDetail.token
      this.hasSupport = this.daoDetail.lp !== '0x0000000000000000000000000000000000000000'
      this.isSupportLp = this.hasSupport
      this.lpAddress = this.hasSupport ? this.daoDetail.lp : ''
      const keys = Object.keys(this.params)
      const params = this.daoDetail.params
      for (const key in params) {
        this.params[keys[key]] = params[key]
      }
      this.imageList.push(
        { url: this.params.logo, isImage: true }
      )
    },
    async handleUpLogo (obj) {
      console.log(obj)
      this.$emit('changeLoading', true)
      const resp = await uploadFile(obj.file)
      console.log(resp)
      this.$emit('changeLoading', false)
      if (!resp.success) {
        this.imageList = []
        return
      }
      this.params.logo = resp.result
    },
    handleChange (falg) {
      this.isSupportLp = falg
    },
    async getTokenInfo (tokenAddress) {
      if (tokenAddress) {
        tokenAddress = tokenAddress.replace(/\s/g, '')
        this.isTokenAddress = this.$web3.isAddress(tokenAddress)
        if (!this.isTokenAddress) return
        this.getTokenName()
        await this.getTokenSupply()
      } else {
        this.tokenName = '-'
        this.totalSupply = 0
      }
    },
    async getTokenName () {
      this.loadingTokenName = true
      const resp = await tokenObj.getSymbol(this.tokenAddress)
      this.loadingTokenName = false
      if (!resp.success) return
      this.tokenName = resp.result
    },
    async getTokenSupply () {
      this.loadingSupply = true
      const resp = await tokenObj.totalSupply(this.tokenAddress)
      this.loadingSupply = false
      if (!resp.success) return
      this.totalSupply = resp.result
    },
    handleAdd () {
      if (this.isSupportLp && !this.lpAddress) { return this.$toast(this.createDao.pleaseInput + 'lpAddress') }
      if (this.isSupportLp && !this.$web3.isAddress(this.lpAddress)) return this.$toast('地址不正确')
      if (parseFloat(this.tokenBalance) < this.totalSupply * this.point) {
        return this.$toast(this.common.notBalance)
      }
      const params = Object.values(this.params)
      const tokenAddress = this.tokenAddress
      const lpAddress = this.isSupportLp ? this.lpAddress : '0x0000000000000000000000000000000000000000'
      this.addDao(params, tokenAddress, lpAddress)
    },
    async addDao (params, tokenAddress, lpAddress) {
      await this.$web3.connectWallet()
      this.loadingAdd = true
      const resp = await daoObj.daoEdit(this.daoAt, params, tokenAddress, lpAddress)
      this.loadingAdd = false
      if (!resp.success) return this.$toast('fail')
      this.$router.replace('/my')
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    localStorage.removeItem('daoDetail')
  }
}
</script>

<style scoped lang="scss">
.page {
  padding: 20px 15px;
  font-size: $font-14;
  .info-box {
    .key-value {
      margin-top: 30px;
    }
    .logo-box {
      width: 94px;
      height: 94px;
      background: $main-bg;
      box-shadow: $shadow;
      border-radius: 8px;
      display: inherit;
      margin: auto;
      // margin-bottom: 20px;
      .upload-icon {
        font-size: 40px;
        width: 100px;
        height: 100px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #979797;
        margin: -5px -2px;
      }
    }
    .textarea-box {
      resize: none;
      padding-left: 10px;
      padding-top: 10px;
      width: 100%;
      border-radius: 8px;
      ::-webkit-input-placeholder {
        color: $color-gray2;
        font-size: $font-12;
      }
    }
    .input-box {
      &.input-address {
        height: 22px !important;
        line-height: 22px !important;
        background: $color-blue1 !important;
        font-size: $font-12;
      }
    }
    .token-tips {
      color: $color-orange;
      font-size: $font-12;
    }
    /deep/ .van-field__value {
      width: 100%;
    }
  }
  .key {
    font-size: $font-14;
    color: $main-color1;
    margin-bottom: 10px;
  }
  .fu-title {
    font-size: $font-12;
  }
  .token-info {
    margin-top: 30px;
    color: $main-color1;
    line-height: 1.5em;
    font-weight: bold;
    .token-green {
      color: $color-blue1;
      margin-left: 5px;
    }
  }
  .lp-info {
    margin-top: 30px;
    .btn-groups {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-weight: bold;
      margin: 0 0 30px 0;
      .btn {
        width: 110px;
        height: 39px;
        background: $color-blue1;
        // background: $blue-bg;
        border-radius: 8px;
        color: $base-color;
        &.active {
          background: $blue-bg;
        }
      }
    }
    .lp-address {
      background: rgba(255, 255, 255, 0.2) !important;
      /deep/ input {
        color: $base-color !important;
      }
    }
  }

  .create-btn {
    width: 167px;
    height: 39px;
    background: $blue-bg;
    border-radius: 20px;
    color: $base-color;
    font-size: $font-16;
    font-weight: bold;
    margin: 35px auto;
    display: inherit;
  }
  /deep/.van-uploader__preview-image,
  .van-uploader__preview {
    width: 94px;
    height: 94px;
  }
  /deep/ .van-image__img {
    border-radius: 8px;
    object-fit: cover;
  }
  /deep/ .van-loading {
    display: inline;
  }
}
</style>
<style lang="scss">
.important {
  color: $color-orange;
  font-size: $font-12;
}
</style>
